import React from 'react';
import ReactDOM from 'react-dom';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import viLocale from '@fullcalendar/core/locales/vi';
import * as bootstrap from 'bootstrap';

$('a[href$="#Modal"]').on( "click", function() {
  $('#Modal').modal('show');
});

const eventModal = document.getElementById('eventModal');
eventModal.addEventListener('show.bs.modal', (data) => {
  const event = data.relatedTarget;

  const eventTitle = eventModal.querySelector('#eventTitle');
  eventTitle.textContent = event.title;

  const eventDateTime = eventModal.querySelector('#eventDateTime');
  const { start, end } = event;
  if (event.allDay) {
    if (start.getDate() === end.getDate() - 1) {
      eventDateTime.textContent = new Intl.DateTimeFormat('vi-VN', { dateStyle: 'full' }).format(start);
    } else {
      const startStr = new Intl.DateTimeFormat('vi-VN', { dateStyle: 'full' }).format(start);
      const endStr = new Intl.DateTimeFormat('vi-VN', { dateStyle: 'full' }).format(new Date(end - 1));
      eventDateTime.textContent = `${startStr} - ${endStr}`;
    }
  } else if (start.getDate() === end.getDate()) {
    const dateStr = new Intl.DateTimeFormat('vi-VN', { dateStyle: 'full' }).format(start);
    const startTimeStr = new Intl.DateTimeFormat('vi-VN', { timeStyle: 'short' }).format(start);
    const endTimeStr = new Intl.DateTimeFormat('vi-VN', { timeStyle: 'short' }).format(end);
    eventDateTime.textContent = `${startTimeStr} - ${endTimeStr}, ${dateStr}`;
  } else {
    const startStr = new Intl.DateTimeFormat('vi-VN', { dateStyle: 'full', timeStyle: 'short' }).format(start);
    const endStr = new Intl.DateTimeFormat('vi-VN', { dateStyle: 'full', timeStyle: 'short' }).format(new Date(end));
    eventDateTime.textContent = `${startStr} - ${endStr}`;
  }

  const eventLocation = eventModal.querySelector('#eventLocation');
  eventLocation.parentElement.removeAttribute('hidden');
  if (event.extendedProps.location) {
    eventLocation.parentElement.removeAttribute('hidden');
  } else {
    eventLocation.parentElement.setAttribute('hidden', '');
  }
  eventLocation.textContent = event.extendedProps.location;

  const eventDescription = eventModal.querySelector('#eventDescription');
  if (event.extendedProps.description) {
    eventDescription.parentElement.removeAttribute('hidden');
  } else {
    eventDescription.parentElement.setAttribute('hidden', '');
  }
  eventDescription.textContent = event.extendedProps.description;
});

const Calendar = () => (
  <FullCalendar
    plugins={[dayGridPlugin, googleCalendarPlugin, bootstrap5Plugin]}
    initialView="dayGridMonth"
    fixedWeekCount={false}
    googleCalendarApiKey="AIzaSyCSnHWZKlwb676kLBY45iFP_IO3w100i00"
    eventSources = {[
      {
        googleCalendarId: 'eagle190110@gmail.com',
      },
      {
        googleCalendarId: '45b983ed8746c092ba2e6046f91f7e15ddb6edd0c052fa854b8a3d7c05edb80a@group.calendar.google.com',
      }
    ]
    }
    locale={viLocale}
    themeSystem="bootstrap5"
    eventTimeFormat={{
      hour: '2-digit',
      minute: '2-digit',
    }}
    headerToolbar={{
      left: 'title',
      center: '',
      right: 'today,prev,next' // user can switch between the two
    }}
    eventTextColor="#000"
    eventColor="#ffc107"
    eventClick={(info) => {
      info.jsEvent.preventDefault();
      new bootstrap.Modal(eventModal).show(info.event);
    }}
  />
);

ReactDOM.render(<Calendar />, document.getElementById('events'));
